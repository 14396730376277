import { createStore } from 'vuex'

export default createStore({
  state: {
    access_token: null,
    parsed_token: null
  },
  mutations: {
    setAccessToken(state, token) {
      state.access_token = token;
    },
    setParsedToken(state, token) {
      state.parsed_token = token;
    },
  },
  actions: {
    setAccessToken(context, token) {
      context.commit('setAccessToken', token);
    },
    setParsedToken(context, token) {
      context.commit('setParsedToken', token);
    },
  },
  getters: {
      getAccessToken(state) {
        return state.access_token;
      },
      getParsedToken(state) {
        return state.parsed_token;
      },
  },
  modules: {
  }
})

<template>
  <div id="content">
      <iframe width="100%" height="100%" frameborder="0" :src="'https://improvers.formstack.com/forms/'+$route.params.id+'?partner_id='+partner_id"></iframe>
  </div>
</template>


<script>
import { useStore } from 'vuex'

export default {
  name: 'Home',
  setup() {
    let store = useStore();
    let token = store.getters.getParsedToken
    let partner_id = btoa('formstring|'+token.partner_id)

    return { partner_id }
  }
}


</script>


<template>
  <router-view/>
</template>

<script>
  import { useRouter } from 'vue-router'
  import {  onMounted } from 'vue'

  export default {
    name: 'App',
    setup() {
      const router = useRouter()

      onMounted(function() {
      // cleanup keycloak URL
        const href = location.href
                .replace(/[&?]code=[^&$]*/, '')
                .replace(/[&?]scope=[^&$]*/, '')
                .replace(/[#?]state=[^&$]*/, '')
                .replace(/[&?]session_state=[^&$]*/, '');
        router.replace(href)
      })
    }
  }
</script>

<style type="text/css">
    body, html
    {
        margin: 0; padding: 0; height: 100%; overflow: hidden;
    }

    #content
    {
        position:absolute; left: 0; right: 0; bottom: 0; top: 0px; 
    }
</style>